@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Barlow&display=swap');

body {
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: 'Barlow', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--primary-color);
}


.offer_box {
  margin-top: "64px";
}

@media (max-width: 786px) {
  .offer_slider {
    display: none;
  }
}

@media (min-width: 789px) {
  .offer_box {
    display: none;
  }
}

/* .slick-prev {
  left: -30px !important;
}
.slick-next {
  right: -30px !important;
}
.slick-prev, .slick-next {  
  width: 30px !important;
  height: 30px !important;
  color: #3BB77E !important;
  border-radius: 50px;
  background: #3BB77E !important;
} */
.slick-prev:before, .slick-next:before {
  font-family: monospace !important;
  font-size: 20px !important;
  opacity: 1 !important;
  top: 5px;
  position: relative;
}

/* .slick-slide div img {
  width: 80% !important;
  height: 420px !important;
} */
.slick-dots {
  position: relative !important;
  top: 25px !important;
}
.slick-dots li {
  width: 100px !important;
  height: 100px !important;
}